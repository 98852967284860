import axios from 'axios'
import store from '@/store'
import router from '@/router/index'

const api = axios.create({
  baseURL: process.env.VUE_APP_URL_SERVIDOR + 'api/',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use(function (config) {
  if (typeof store.state.usuario.token !== 'undefined') {
    config.headers.Authorization = `Bearer ${store.state.usuario.token}`
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    if (error.response && error.response.status === 401 && store.state.usuario.token) {
      store.dispatch('logout')
      router.push('/login')
    }
    return Promise.reject(error)
  },
) 

export default api
